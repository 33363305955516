<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>账户管理</p>
			<i>/</i>
			<p @click="jumpPage">角色列表</p>
			<i>/</i>
			<p class="active">角色编辑</p>
		</nav>

		<div class="page_edit">

			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="name">
						<p class="form_label_p">角色名称</p>
						<el-input class="input_long" v-model="ruleForm.name" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="" prop="rank">
						<p class="form_label_p">级别</p>
						<el-input class="input_long" v-model="ruleForm.rank" placeholder="数字越小越靠前">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="cat_id">
						<p class="form_label_p">归属部门</p>
						<el-select class="input_long" v-model="ruleForm.cat_id" :disabled="disabled" filterable
							placeholder="请选择角色所归属部门">
							<el-option v-for="item in departmentData" :key="item.id" :label="item.class_name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<div class="el-form-item_last"></div>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>

		</div>

	</div>
</template>

<script>
	export default {
		name: 'roleEdit',
		data() {
			return {
				ruleForm: {
					name: '',
					rank: '',
					cat_id: '',
				},
				rules: {
					name: [{
						required: true,
						message: '名称不能为空',
						trigger: 'blur'
					}],
					rank: [
						{
							required: true,
							message: '角色级别不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^\+?[1-9][0-9]*$/,
							message: '只能是非0的正整数',
						},
					],
					cat_id: [{
						required: true,
						message: '请选择所属部门',
						trigger: 'change'
					}],
				},
				modifyBtn: true, // 默认创建
				disabled: false, // 默认可修改
				departmentData: [], // 部门ID
			}
		},
		components: {

		},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;
				this.disabled = true;
				
				// 获取角色详情做修改
				this.getRoleInfo();
			}
			
			// 获取部门列表
			this.getDepartmentList();
		},
		methods: {
			// 获取部门列表
			getDepartmentList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}admin/classlist`,
					data: {}
				}).then( res => {
					if(res.code == '0'){
						this.departmentData = res.data;
					}
				})
			},
			
			// 获取角色详情做修改
			getRoleInfo(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}admin/roleinfo`,
					data: {
						role_id: this.$route.query.id,
					}
				}).then( res => {
					if(res.code == '0'){
						this.ruleForm.cat_id = res.data.class_id;
						this.ruleForm.name = res.data.role_name;
						this.ruleForm.rank = res.data.role_rank;
					}
				})
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}admin/addrole`,
							data: {
								class_id: this.ruleForm.cat_id,
								role_name: this.ruleForm.name,
								role_rank: this.ruleForm.rank,
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}admin/editrole`,
							data: {
								role_id: this.$route.query.id,
								class_id: this.ruleForm.cat_id,
								role_name: this.ruleForm.name,
								role_rank: this.ruleForm.rank,
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},

			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
</style>
